import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import generateHTML from '../utils/generateHTML';
import Layout from '../components/Layout';
import SEO from '../components/Seo'
import Content, { HTMLContent } from '../components/Content'
import { UsecaseHeroTriangle } from '../components/Triangle'
import ContentHero from '../components/ContentHero'
import CtaSection from '../components/CtaSection'

const ContentWrap = styled.div`
  & table {
    width: auto;
    border-spacing: 5px !important;
    border-collapse: separate;
  }
  & th {
    font-size: 16px important;
    line-height: 34px !important;
    color: #213F51 !important;
    padding: 5px 10px !important;
    border: 0px !important;
  }
  & tr:nth-child(2n+1) {
    & td {
      border: 0px !important;
      padding: 5px 10px;
      background: #F6F9F9;
    }
  }
  & tr:nth-child(2n) {
    & td {
      border: 0px !important;
      padding: 5px 10px;
    }
  }
`
export const ContentPageTemplate = ({
  hero,
  content,
}) => {
  const PostContent = HTMLContent || Content;
  return (
    <div>
      <ContentHero data={hero} />
      <UsecaseHeroTriangle />
      <div className="content">
        <section className="section">
          <div className="container">
            <ContentWrap>
              <PostContent
                content={generateHTML(content)}
              />
            </ContentWrap>
          </div>
        </section>
      </div>
      <CtaSection bgClassName="is-gray-bg" />
    </div>
  )
}

ContentPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  hero: PropTypes.object,
}

const ContentPage = ({ data }) => {
  const { markdownRemark } = data

  return (
    <Layout>
      <SEO
        title={markdownRemark.frontmatter.seoTitle}
        description={markdownRemark.frontmatter.seoDescription}
      />
      <ContentPageTemplate
        content={markdownRemark.html}
        hero={markdownRemark.frontmatter.hero}
      />
    </Layout>
  )
}

ContentPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ContentPage

export const pageQuery = graphql`
  query ContentPagesById($id: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoTitle
        seoDescription
        hero {
          title
          subtitle
        }
      }
      html
    }
  }
`
